import Vue from 'vue'
import App from './App.vue'
// import './registerServiceWorker'
import router from './router'
import store from './store'

// 引入全局css
import './components/common.css'
// 引入icon
import './assets/font/iconfont.css'
// 引入字体包css
import './assets/fontpackStyle/fontstyle.css'

// 导入i18n
import i18n from './utils/i18n/i18n';


import 'wowjs/css/libs/animate.css'
import wow from 'wowjs'
Vue.prototype.$wow = wow


import { isMobile } from '@/utils/utils'
let isMobiles = isMobile()
if (isMobiles) {
  // 引入移动端rem
  import('./utils/mobileRem')
}

// 引入 Element 的全部组件
import ElementUI from 'element-ui'
// 引入 Element 样式
import 'element-ui/lib/theme-chalk/index.css'
import locale from 'element-ui/lib/locale/lang/zh-CN'
// 全局注册bannder组件
Vue.component('bannder-img-video', () => import('@/components/bannderImgVideo.vue'));
Vue.component('bannder-mobile-video', () => import('@/components/mobilebannderVideo.vue'));
Vue.use(ElementUI, { locale })


Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
