import enLocale from 'element-ui/lib/locale/lang/en' //引入element语言包
const en = {
  header: {
    home: 'Home',
    yewu: 'Business',
    xiangmu: 'Project',
    zhuanjia: 'Expert',
    xueyuan: 'College',
    zhaopin: 'Recruit',
    xinwen: 'News',
    guanyu: 'About',
    lianxi: 'Contact',
    yxt: "Game Special Effects",
    yxd: "Game Animation",
    djm: "3D Game Modeling",
    yx: "Game Artwork"
  },
  home: {
    mjnet: 'MingJiang Network',
    art: 'Production specialist',
    guoneiwai: 'Home and abroad',
    kehu: 'Client',
    cpxm: 'Product Item',
    ywhp: 'Business Praise',
    jpgzs: 'Boutique Studio',
    mjxy: 'MingJiang College',
    hyzj: 'Industry Expert',
    ywfb: 'Service Distribution',
    yxkflc: 'Game Development Process',
    jxxm: 'Selected Item',
    khpj: 'Customer Evaluation',
    xw: 'News',
    more: 'more',
    lookM: 'See more',
    syg: 'previous',
    xyg: 'next',
  },
  vovation: {
    ywfl: 'Service Classification',
    yzxm: 'Quality Works',
  },
  project: {
    yzxm: 'Quality Project',
    zwgd: 'NO MORE',
    xmbj: 'Project Background',
    xmcg: 'Project Outcome',
  },
  special: {
    jpgzs: 'Boutique Studio',
    hyzj: 'Industry Expert',
    cyal: 'Participation Case'
  },
  college: {
    hyxz: 'Industry Selection',
    zyjs: 'Professional',
    wlfz: 'Future Development Direction',
    kcap: 'Course Arrangment',
    sjap: '（4-5 months）The course is based on practical arrangements',
    wmys: 'Our Advantage',
    szjs: 'Teacher Introduction',
    xyjs: 'College Introduction',
    gszp: 'Company Work',
    xyzp: 'Student Work',
    xydt: 'College Dynamics',
    xxwc: 'HI, please check wechat public account for more information',
    xxew: 'Scan the QR code on the left for details'
  },
  recruit: {
    zxns: 'Recruit Person',
    zpzw: 'Recruitment Position',
    search: 'Search for jobs, enter search',
    ly: 'Select a field',
    lyl: 'Domain',
    td: 'Choose a team',
    tdt: 'Team',
    dd: 'Choose a location',
    dzd: 'Locations',
    cz: 'Controls',
    ckgd: 'See more',

    zwxx: 'Job Information',
    zz: 'Duty',
    yq: 'Demand',
    sqgw: 'Apply For This Position',
    jlsc: 'Resume Upload',
    xzwj: 'Select file',
    zcgs: 'Support format',
    xm: 'Name',
    nl: 'Age',
    yx: 'Email',
    sjhm: 'Mobile Number',
    tj: 'submit',

    qname: 'Please fill in your name',
    qphone: 'Please fill in your mobile phone number',
    qtruephone: 'Please fill in the correct mobile phone number',
    qemail: 'Please fill in the email',
    qtruemail: 'Please fill in the correct email address',
    qage: 'Please fill in your age',
  },
  new: {
    gsxw: 'Company News',
    syp: 'Previous article',
    xyp: 'Next article',
  },
  about: {
    qyjzg: 'Corporate Values',
    fzlc: 'Development Course',
    mjtd: 'MingJiang Team',
    gsfw: 'Company Atmosphere',
  },
  contact: {
    hello: 'Hello',
    lt: 'Listen carefully to your needs',
    gz: "It's our most important job.",
    name: 'Name',
    gsmc: 'Company name',
    lxfs: 'Contact information',
    xq: 'Your needs',
    qsr: 'Please enter',
    tj: 'submit',
    qxfgs: 'Subsidiary Company',
    qtruemobile: 'Please enter the correct one',
    qgs: 'Please fill in the phone number in the correct format',
    neirong: 'Please fill in the complete content',
    qsrxm: 'Please enter your name',
    qsrgsmc: 'Please enter the company name',
    qsrlxfs: 'Please enter your contact information',
    qsrxq: 'Please enter your requirements'
  },
  ...enLocale //这里引入element语言包目的是在切换语言的时候，element的组件里面的文字能跟随切换
}
export default en;
