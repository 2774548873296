import zhLocale from 'element-ui/lib/locale/lang/zh-CN' //引入element语言包
const cn = {
  header: {
    home: '首页',
    yewu: '业务',
    xiangmu: '项目',
    zhuanjia: '专家',
    xueyuan: '学院',
    zhaopin: '招聘',
    xinwen: '新闻',
    guanyu: '关于',
    lianxi: '联系',
    yxt: "游戏特效",
    yxd: "游戏动画",
    djm: "3D游戏建模",
    yx: "游戏原画 "
  },
  home: {
    mjnet: '名匠网络',
    art: '制作专家',
    guoneiwai: '国内外',
    kehu: '客户',
    cpxm: '产品项目',
    ywhp: '的业务好评',
    jpgzs: '精品工作室',
    mjxy: '名匠学院',
    hyzj: '行业专家',
    ywfb: '业务分布',
    yxkflc: '游戏开发流程',
    jxxm: '精选项目',
    khpj: '客户评价',
    xw: '新闻',
    more: '更多',
    lookM: '查看更多',
    syg: '上一个',
    xyg: '下一个',
  },
  vovation: {
    ywfl: '业务分类',
    yzxm: '优质作品',
  },
  project: {
    yzxm: '优质项目',
    zwgd: '暂无更多',
    xmbj: '项目背景',
    xmcg: '项目成果',
  },
  special: {
    jpgzs: '精品工作室',
    hyzj: '行业专家',
    cyal: '参与案例'
  },
  college: {
    hyxz: '行业选择',
    zyjs: '专业介绍',
    wlfz: '未来发展方向',
    kcap: '课程安排',
    sjap: '（4-5个月）课程以实际安排为主',
    wmys: '我们的优势',
    szjs: '师资介绍',
    xyjs: '学院介绍',
    gszp: '公司作品',
    xyzp: '学员作品',
    xydt: '学院动态',
    xxwc: 'HI，详细信息请查看微信公众号',
    xxew: '扫描左侧二维码查看详情'
  },
  recruit: {
    zxns: '招贤纳士',
    zpzw: '招聘职位',
    search: '搜索职位,回车搜索',
    ly: '选择一个领域',
    lyl: '领域',
    td: '选择一个团队',
    tdt: '团队',
    dd: '选择一个地点',
    dzd: '地点',
    cz: '操作',
    ckgd: '查看更多',

    zwxx: '职位信息',
    zz: '职责',
    yq: '要求',
    sqgw: '申请此岗位',
    jlsc: '简历上传',
    xzwj: '选择文件',
    zcgs: '支持格式',
    xm: '姓名',
    nl: '年龄',
    yx: '邮箱',
    sjhm: '手机号码',
    tj: '提交',

    qname: '请填写姓名',
    qphone: '请填写手机号',
    qtruephone: '请填写正确的手机号',
    qemail: '请填写邮箱',
    qtruemail: '请填写正确的邮箱',
    qage: '请填写年龄',
  },
  new: {
    gsxw: '公司新闻',
    syp: '上一篇',
    xyp: '下一篇',
  },
  about: {
    qyjzg: '企业价值观',
    fzlc: '发展历程',
    mjtd: '名匠团队',
    gsfw: '公司氛围',
  },
  contact: {
    hello: '您好',
    lt: '仔细聆听您的需求',
    gz: '是我们的最重要的工作。',
    name: '姓名',
    gsmc: '公司名称',
    lxfs: '联系方式',
    xq: '您的需求',
    qsr: '请输入 ',
    tj: '提交',
    qxfgs: '旗下分公司',
    qtruemobile: '请输入正确的',
    qgs: '请填入正确格式的手机号',
    neirong: '请填写完整内容',

    qsrxm: '请输入姓名',
    qsrgsmc: '请输入公司名称',
    qsrlxfs: '请输入联系方式',
    qsrxq: '请输入您的需求'
  },
  ...zhLocale //这里引入element语言包目的是在切换语言的时候，element的组件里面的文字能跟随切换

}
// 导出
export default cn;
